import React, { Component } from "react";
import BackgroundImage from 'gatsby-background-image'
import { gsap } from 'gsap'
import { window } from "browser-monads";
import { scroll } from "../theme";
import utils from "./utils";
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import CircleLink from "./circle-link";
import WebImage from "./image";

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <>
          <h2>Embedded Asset</h2>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      )
    },
  },
}

export default class Service extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.ref = null
    this.imageEl = null
    this.titleEl = null
    this.descriptionEl = null
  }

  toggleContent() {
    if (!utils.isDesktop()) {
      if (this.state.isOpen) {
        this.closeContent()
      } else {
        this.openContent()
      }
    }
  }

  openContent() {
    this.setState({
      isOpen: true
    })
  }

  closeContent() {
    this.setState({
      isOpen: false
    })
  }

  componentDidMount() {
    setTimeout(() => {
      gsap.from(this.titleEl, {
        scrollTrigger: {
          trigger: this.ref,
          scroller: scroll.container,
          start: "top top+=200"
        },
        duration: 1.5,
        delay: 0.2,
        y: utils.isDesktop() ? 50 : 0,
        opacity: 0,
        ease: "power2.out"
      })
      gsap.from(this.subEl, {
        scrollTrigger: {
          trigger: this.ref,
          scroller: scroll.container,
          start: "top top+=200"
        },
        duration: 1.5,
        opacity: 0,
        delay: 0.5,
        ease: "power2.out"
      })
    }, 200)
  }

  desktopAnimation(index) {
    window.locomotiveScroll.stop()
    window.locomotiveScroll.scrollTo(`#service-${index}`, {
      duration: 100
    })
  }

  render() {
    const { left, name, shortDescription, image, slug, index } = this.props

    return (
      <section id={`service-${index}`} ref={el => this.ref = el} className="bg-sand min-h-screen h-mobile-landscape-150vh lg:flex relative overflow-hidden" style={{
        zIndex: 1
      }}>
        <div onClick={() => {
          this.toggleContent()
        }} className={`service-content ${this.state.isOpen ? 'service-content--open' : ''} flex flex-0 justify-end flex-col px-4 pt-2 lg:pt-12 lg:px-12 pb-8 lg:pb-12 p-12 absolute bottom-0 lg:relative w-full lg:w-1/2 z-10 lg:z-auto ${left ? 'order-2' : ''}`}>
          <h3 ref={el => this.titleEl = el} className="service-content-title">{name}</h3>
          <div className="max-w-lg" ref={el => this.subEl = el} >
            <div className="mb-4">
              {renderRichText(shortDescription, options)}
            </div>
            <CircleLink cssClass="" title="Read More" isLink={true} to={`/service/${slug}`} duration={utils.getScrollToDuration()} side={left ? 'left' : 'right'} onPress={() => {
              utils.isDesktop() ? this.desktopAnimation(index) : utils.fadeOutFromPurple()
            }} />
          </div>
        </div>
        <WebImage backgroundColor={utils.getPurpleHex()} image={image} innerCssClass="w-full h-full" outerCssClass={`flex-0 h-screen h-mobile-landscape-150vh w-full lg:w-1/2 ${left ? 'order-1' : ''}`} />
        <div className="service-mobile-shadow"></div>
      </section>
    )
  }
}
